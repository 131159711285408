$checkboxSize: 20px;
$uncheckedBG: #fff;
$checkedBG: #ff9797;
$checkedColor: #fff;

.custom-checkbox-label {
    line-height: 35px;
    color: #444;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
}

.custom-checkbox {
    display: none;
}

.custom-checkbox {
    +span {
        border-radius: 5px;
        border: 1px solid #ff9797;
        display: flex;
        width: $checkboxSize;
        height: $checkboxSize;
        background: $uncheckedBG;
        align-items: center;
        justify-content: center;
        margin: 5px;
        box-sizing: border-box;
        transition: all 150ms;
    }

    &:checked {
        +span {
            border-width: $checkboxSize / 2;
            &::before {
                    content: "";
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    padding: 1px;
                    margin-bottom: 1px;
                    border: solid $checkedColor;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
            }
        }
    }
}