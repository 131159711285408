.container .headerTabs {
  .react-tabs__tab {
    background: #f8f8f8;
    border: none;
    padding: 19px 40px 20px;
    font-size: 22.5px;
    color: #6d7077;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    @media screen and (max-width: 992px) {
      font-size: 12px;
      padding: 13px 33px 13px;
    }
  }
  .react-tabs__tab--selected {
    background: #ff9797;
    border: none;
    padding: 19px 40px 20px;
    font-size: 22.5px;
    color: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
    @media screen and (max-width: 992px) {
      font-size: 12px;
      padding: 13px 33px 13px;
    }
  }
  .react-tabs__tab-list {
    border-bottom: none;
    margin: 0;
    padding: 0;
  }
}
