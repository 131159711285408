$darkPink: #ff9797;
$lightPink: #fec1c11c;
$white: #ffffff;

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
}
.row {
  width: 100%;
}

.container {
  width: auto;
  max-width: 2200px;
  margin: 0 auto;
  border: 1px solid #ccc9c9;
  margin-top: 50px;
  @media screen and (max-width: 992px) {
    border: none;
    margin-top: 0;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  // text-align: center;
  button {
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  @media screen and (max-width: 992px) {
    //@function: column;
    padding-bottom: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  h1 {
    font-size: 30px;
    padding: 5px 0 25px;

    @media screen and (max-width: 992px) {
      font-size: 16px;
      padding: 20px 0 20px;
      text-align: center;
    }
  }
  .gmv {
    background: $darkPink;
    border: none;
    padding: 19px 40px 20px;
    font-size: 22.5px;
    color: #fff;
    border-radius: 5px;
  }
  .net {
    background: #f8f8f8;
    border: none;
    padding: 19px 40px 20px;
    font-size: 22.5px;
    color: #6d7077;
    border-radius: 5px;
  }
}

.section {
  background: #f2f3f8;
  padding: 27px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
  .content {
    padding: 25px;
    border-radius: 6px;
    @media screen and (max-width: 992px) {
      padding: 0;
    }
    .no-data {
      width: 20%;
      @media screen and (max-width: 992px) {
        width: 71%;
      }
      @media screen and (max-width: 320px) {
        width: 71%;
      }
    }
    background: #fff;
    .dateSelection {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      align-items: center;
      @media screen and (max-width: 992px) {
        justify-content: center;
        margin-bottom: 20px;
      }
      .dateBtn {
        background: transparent;
        color: #202841;
        font-size: 18px;
        //line-height: 1.87;
        border: solid 1.4px #e8e9ec;
        margin-right: 27px;
        padding: 11px 20px;
        border-radius: 5px;
        cursor: pointer;
        @media screen and (max-width: 992px) {
          margin-right: 10px;
          font-size: 12px;
        }
        @media screen and (max-width: 320px) {
          font-size: 11px;
        }
      }

      .customdropdown {
        margin-right: 30px;
        position: relative;
        @media screen and (max-width: 992px) {
          margin-right: 0;
        }
        select {
          background-color: #fff;
          color: #000;
          border: solid 1.4px #e8e9ec;
          // background-image: url("/images/dropdown-black.svg");
          border: solid 1.4px #e8e9ec;

          // background: $darkPink;
          // color: #fff;
          font-size: 18px;
          //line-height: 1.87;
          padding: 11px 45px 11px 20px;
          // border: none;
          border-radius: 5px;
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */
          appearance: none;
          z-index: 999999;
          // background-image: url("/images/dropdown.svg");
          background-repeat: no-repeat;
          background-position: 94% 50%;
          @media screen and (max-width: 992px) {
            margin-right: 0;
            font-size: 12px;
            padding: 12px 35px 12px 16px;
          }

          &:focus {
            outline: none;
          }
          option {
            background-color: $darkPink;
            color: #fff;
            //  width: 20%;
          }
        }
        .selectedMonth {
          background-color: $darkPink;
          color: #fff;

          // background: #fff;
          // color: #000;
          // border: solid 1.4px #e8e9ec;
          //background-image: url("/images/dropdown.svg");
          background-repeat: no-repeat;
          background-position: 94% 50%;
        }
      }

      .datePickerDesktop {
        display: flex;
        align-items: center;
        .react-datepicker-wrapper {
          margin-right: 10px;
        }
        input {
          font-size: 16px;
          border-radius: 5px;
          padding: 5px 5px;
          box-shadow: none;
          border: 2px solid #aaadb7;
        }
        span {
          font-size: 14px;
          line-height: 2.41;
          color: #283157;
        }
        @media screen and (max-width: 992px) {
          display: none;
        }
      }
      .downloadBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
        @media screen and (max-width: 992px) {
          display: none;
        }
      }
    }

    .dateSelection button:last-child {
      margin-right: 0;
    }
  }
}

.salesOverviewFigures {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  h5 {
    font-size: 24px;
    margin-right: 20px;
    font-weight: 500;
  }
  p {
    font-size: 21px;
  }
  span {
    font-size: 21px;
    font-weight: 500;
  }
}

// .salesOverview {
//   display: flex;
//   justify-content: end;
//   flex-wrap: wrap;
//   margin-bottom: 40px;
//   .salesOverviewBlock {
//     padding: 24px 15px 10px 20px;
//     border-radius: 8px;
//     margin-right: 25px;
//     margin-bottom: 15px;
//     width: 175px;
//     .salesOverDesktopicons {
//       display: flex;
//       align-items: center;
//       margin-bottom: 20px;
//       img {
//         margin-right: 15px;
//       }
//       h6 {
//         font-size: 16px;
//         padding: 0;
//         margin: 0;
//       }
//     }
//     p {
//       font-size: 16px;
//       margin-bottom: 15px;
//       span {
//         font-size: 18px;
//         font-weight: 500;
//       }
//     }
//   }
//   .salesOverviewBlock:last-child {
//     margin-right: 0;
//   }
// }

.stats {
  overflow-x: auto;
  font-size: 14px;
  h2 {
    margin-bottom: 22px;
  }
  table {
    width: 100%;
    text-align: center;
    border-spacing: 1px;
  }
  td {
    padding: 21px 10px;
  }
  tr.thWhite th {
    color: #fff;
    padding: 19px 0;
    img {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  tr.OrdRev th {
    padding: 21px 15px;
    font-weight: 500;
  }
    tr.thDefault th {
    padding: 19px 0;
    img {
      vertical-align: middle;
    }
  }

  .OrdRev {
    .channelLight {
      color: #dedede;
      color: #000;
    }
    .websiteLight {
      color: #6570c0;
    }
    .mobileWebsiteLight {
      color: #5984c9;
    }
    .androidLight {
      color: #58b7ca;
    }
    .iOSLight {
      color: #6f59ca;
    }
    .adminPanelLight {
      color: #9e58af;
    }
    .onlineLight {
      color: #5585b0;
    }
    .posLight {
      color: #68bf65;
    }
    .totalAmtLight {
      color: #ff9797;
    }
    .seller_appLight {
      color: #5585b0;
    }
  }
  .websiteDark {
    background: #6570c0;
  }
  .mobileWebsiteDark {
    background: #5984c9;
  }
  .androidDark {
    background: #58b7ca;
  }
  .iOSDark {
    background: #6f59ca;
  }
  .adminPanelDark {
    background: #9e58af;
  }
  .onlineDark {
    background: #5585b0;
  }
  .posDark {
    background: #68bf65;
  }
  .channel {
    background: #a9a9a9;
    padding: 0 31px 0 !important;
  }
  .channelLight {
    text-align: center;
    background: #a9a9a9;
    padding-left: 15px;
  }
  .brand {
    background: #dcdcdc;
    padding: 0 31px 0 !important;
    color:#444;
  }
}

.websiteLight {
  background: #c1c6e6;
}

.mobileWebsiteLight {
  background: #bdcee9;
}

.androidLight {
  background: #bce2ea;
}

.iOSLight {
  background: #c5bdea;
}

.adminPanelLight {
  background: #d8bcdf;
}

.onlineLight {
  background: #bbcedf;
}

.posLight {
  background: #c3e5c1;
}
.totalAmt {
  background: #ff9797;
}
.totalAmtLight {
  background: #ffd5d5;
}
.seller_appLight {
  background: #d5e9ff;
}
.seller_appDark {
  background: #5e94ca;
}

.modalStats {
  .popUpcontent {
    display: flex;
    justify-content: space-between;
  }
  button {
    background: transparent;
    border: none;
    font-size: 16px;
    width: 16px;
    height: 16px;
  }

  th {
    color: #a0a4b3;
    font-size: 17px;
    padding: 15px 0;
  }

  td {
    font-size: 17px;
    padding: 15px 0;
  }

  h1 {
    margin: 0;
    text-align: center;
    font-size: 21px;
    padding: 15px 0 20px;
  }
  h2 {
    margin: 0;
    font-size: 18px;
  }
  h3 {
    margin: 0;
  }
  h4 {
    margin: 0;
  }
  h5 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  .contentLeft {
    text-align: left;
  }
  .contentRight {
    text-align: right;
  }
  .react-tabs__tab {
    background: #f8f8f8;
    border: none;
    padding: 15px 85px 15px;
    color: #6d7077;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .react-tabs__tab--selected {
    background: #ff9797;
    border: none;
    padding: 15px 85px 15px;
    color: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .react-tabs__tab-list {
    text-align: center;
    border-bottom: none;
    margin: 0;
    padding: 0;
  }
}

.statsMobile {
  h1 {
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  h5 {
    text-align: center;
    font-size: 0.83em;
    margin-bottom: 15px;
  }
  .mobileOrderDate {
    h4 {
      text-align: center;
      color: #8b909e;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      span {
        color: #202841;
      }
    }
    div[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .statsMobileTable {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 12px;
      font-weight: 500;
      line-height: 2.27;
      padding: 16px 0;
      background: #f2f3f7;
    }

    h4 {
      font-size: 14px;
      @media screen and (max-width: 320px) {
        font-size: 12px;
      }
    }

    .icons {
      display: flex;
      align-items: center;
      padding: 16px 11px 16px 14px;
      // border-bottom: 1px solid #fff;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
    .orderRevenue {
      min-height: 52px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      // border-bottom: 1px solid #fff;
    }
    .channel {
      width: 43%;
      // border-right: 1px solid #fff;
      h2 {
        padding: 16px 0px 16px 14px;
        border-bottom: 1px solid #fff;
        color: #939393;
      }
    }

    .orders {
      width: 20%;
      // border-right: 1px solid #fff;
      p {
        text-decoration: underline;
        // color: #232c4b;
        @media screen and (max-width: 320px) {
          font-size: 13px;
        }
      }
      h2 {
        padding: 16px 12px 16px 19px;
        text-align: right;
        border-bottom: 1px solid #fff;
        color: #939393;
      }
    }
    .revenue {
      width: 37%;
      h2 {
        padding: 16px 12px 16px 30px;
        text-align: right;
        border-bottom: 1px solid #fff;
        color: #939393;
      }
      p {
        color: #283157;
        @media screen and (max-width: 320px) {
          font-size: 13px;
        }
      }
    }
    .totalText {
      background: $darkPink;
      min-height: 60px;
      h4 {
        padding: 16px 12px 17px 14px;
        font-weight: 600;
        color: $white;
        font-size: 16px;
        text-transform: uppercase;
        @media screen and (max-width: 320px) {
          font-size: 14px;
        }
      }
    }
    .totalAmt {
      background: $lightPink;
      min-height: 60px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      p {
        // padding: 16px 12px 17px 14px;
        font-weight: 600;
      }
    }
    .reportData {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      height: min-content;
      border-spacing: 3px;
      border-collapse: separate;

      td,
      th {
        //   border: 1px solid #dddddd;
        text-align: left;
        // padding: 8px;
      }
    }
  }
  .slick-disabled {
    cursor: unset;
    opacity: 0.6;
  }
  .slick-next {
    //background: url("/images/right-arrow.svg") center center no-repeat !important;
    right: 14px;
    top: -16px;
    &:before {
      content: "";
    }
  }
  .slick-prev {
    //background: url("/images/left-arrow.svg") center center no-repeat !important;
    left: 14px;
    top: -16px;
    &:before {
      content: "";
    }
  }
}

.mobileSalesTitle {
  h1 {
    text-align: center;
    font-size: 16px;
    margin: 25px 0 25px;
  }
}

.spinner {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 40px;
}

button:focus {
  outline: none;
}

.salesOverviewMobile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media screen and (max-width: 992px) {
    justify-content: space-between;
    margin: 0 8px;
  }
  @media screen and (max-width: 320px) {
    margin: 0 7px;
  }
  // .ordRev {
  //   h2 {
  //     font-size: 16px;
  //     font-weight: normal;
  //     margin-bottom: 5px;
  //     @media screen and (max-width: 320px) {
  //       font-size: 13px;
  //     }
  //   }
  //   h3 {
  //     font-size: 16px;
  //   }
  // }
  .ordRev {
    p {
      font-size: 16px;
      margin-bottom: 25px;
      span {
        font-size: 15px;
        font-weight: 500;
      }
    }
    .orderSpan {
      font-size: 16px;
      font-weight: normal;
      margin-top: 14px;

      span {
        padding-top: 11px;
        //display: block;
        margin-bottom: 20px;
      }
    }
    @media screen and (max-width: 992px) {
      .revenue {
        padding-top: 7px;
        display: block;
      }
    }
    h3 {
      font-size: 16px;
    }
  }
  * {
    box-sizing: border-box;
  }
  .salesOverviewBlockParent {
    // width: 175px;

    @media screen and (max-width: 992px) {
      width: 50%;
      padding: 5px;
    }
  }
  .salesOverviewBlock {
    padding: 24px 15px 10px 20px;
    border-radius: 8px;
    // margin-bottom: 10px;
    width: 215px;
    @media screen and (min-width: 992px) {
      margin-right: 25px;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 992px) {
      padding: 15px 11px 10px 15px;
      // min-width: 40vw;
      width: 46vw;
      .revenue {
        padding-top: 10px;
        display: block;
        font-weight: 500;
        font-size: 14px;
      }
    }

    @media screen and (max-width: 320px) {
      padding: 15px 7px 18px 13px;
      min-width: 38vw;
    }
    //desktop

    .salesOverDesktopicons {
      display: flex;
      align-items: center;
      @media screen and (min-width: 992px) {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 320px) {
        margin-bottom: 12px;
      }
      img {
        margin-right: 15px;
        @media screen and (max-width: 992px) {
          margin: 0 9px 15px 0;
          height: 28px;
          width: 28px;
        }

        @media screen and (max-width: 320px) {
          margin: 0px 7px 0px 0;
          width: 20px;
          height: 20px;
        }
      }
      h6 {
        font-size: 16px;
        padding: 0;
        margin: 0;
        @media screen and (max-width: 992px) {
          font-size: 13px;
          margin: 0 0 11px 0;
        }
        @media screen and (max-width: 320px) {
          font-size: 13px;
          margin: 0;
        }
      }
    }
    p {
      font-size: 14px;
      // margin-bottom: 15px;
      span {
        font-size: 14px;
        font-weight: 500;
        @media screen and (max-width: 320px) {
          font-size: 13px;
        }
      }
      .ordersCount {
        // margin-left: 15px;
        margin-left: 2px;
      }
    }
  }
  .salesOverviewBlock:last-child {
    //margin-right: 0;
  }
}

.modalStatsMobile {
  margin: 0 11px;
  .userModalContainer {
    max-height: 300px;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 992px) {
      max-height: 210px;
    }
  }
  .popUpcontent {
    display: flex;
    justify-content: space-between;
  }
  button {
    background: transparent;
    border: none;
    margin-top: 10px;
  }

  th {
    color: #a0a4b3;
    font-size: 12px;
    padding: 15px 2px;
    border-bottom: 1px solid #e8e9ec;
  }

  td {
    font-size: 12px;
    padding: 12px 0;
    border-bottom: 1px solid #e8e9ec;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    padding: 15px 0 20px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    margin-top: -4px;
    @media screen and (max-width: 992px) {
      margin-top: 16px;
    }
  }
  h2 {
    font-size: 12px;
  }
  .contentLeft {
    text-align: left;
  }
  .contentRight {
    text-align: right;
  }
  .react-tabs__tab {
    background: #f8f8f8;
    border: none;
    padding: 12px 30px 12px;
    color: #6d7077;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .close {
    margin-right: -16px;
    margin-top: -1px;
    @media screen and (max-width: 992px) {
      margin-top: -4px;
      margin-right: 6px;
    }
  }
  .react-tabs__tab--selected {
    background: #ff9797;
    border: none;
    //padding: 15px 45px 15px;
    padding: 12px 30px 12px;
    color: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .react-tabs__tab-list {
    text-align: center;
    border-bottom: none;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 320px) {
    .react-tabs__tab {
      padding: 12px 30px 12px;
    }
    .react-tabs__tab--selected {
      padding: 12px 31px 12px;
    }
  }
}

.mobileReports {
  text-align: center;
  margin-bottom: 30px;
  h2 {
    font-size: 12px;
  }
  .react-tabs__tab {
    background: #f8f8f8;
    border: none;
    padding: 13px 28px 15px;
    font-size: 12px;
    color: #6d7077;
    border-radius: 20px;
    margin-right: 10px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  .react-tabs__tab--selected {
    background: #ff9797;
    border: none;
    padding: 13px 28px 15px;
    font-size: 12px;
    color: #fff;
    border-radius: 20px;
    font-weight: normal;
    margin-right: 10px;
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}

.datePickerMobile {
  display: flex;
  justify-content: space-evenly;
  margin: 0 15px 15px;
  @media screen and (max-width: 320px) {
    .react-datepicker-wrapper {
      margin: 0px 5px 0 0;
    }
  }
  input {
    font-size: 12px;
    border-radius: 5px;
    padding: 5px 5px;
    box-shadow: none;
    border: 2px solid #aaadb7;
    @media screen and (max-width: 320px) {
      font-size: 11px;
    }
  }
  span {
    font-size: 14px;
    line-height: 2.41;
    color: #283157;
  }
}

@media screen and (min-width: 992px) {
  .mobileDetailedReports {
    display: none;
  }
  .datePickerMobile {
    display: none;
  }
  .mobileSalesTitle,
  .ordRev {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .desktop-only {
    display: none;
  }
  .salesOverviewFigures {
    display: none;
  }
  .salesOverview {
    display: none;
  }
  .stats {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  // .react-datepicker {
  //   left: -93px;
  // }

  // .react-datepicker-popper[data-placement^="bottom"]
  //   .react-datepicker__triangle {
  //   left: 165px;
  // }
}
.reportData {
  .orderRevenue {
    box-shadow: none;
  }
}
//.salesOverviewBlock {
.grand {
  background: #f0f0f0;
  box-shadow: inset 5px 0 0 0 #a9a9a9;
  h6,
  h4 {
    color: #000000;
    margin-top: -6px;
  }
}
.website {
  background: #6570c04d;
  box-shadow: inset 5px 0 0 0 #6570c0;
  h6,
  h4 {
    color: #6570c0;
  }
}

.mobileWebsite {
  background: #5984c94d;
  box-shadow: inset 5px 0 0 0 #5984c9;
  h6,
  h4 {
    color: #5984c9;
  }
}

.android {
  background: rgba(88, 183, 202, 0.3);
  box-shadow: inset 5px 0 0 0 #58b7ca;
  h6,
  h4 {
    color: #58b7ca;
  }
}
.iOS {
  background: rgba(111, 89, 202, 0.3);
  box-shadow: inset 5px 0 0 0 #6f59ca;
  h6,
  h4 {
    color: #6f59ca;
  }
}
.adminPanel {
  background: rgba(158, 88, 175, 0.3);
  box-shadow: inset 5px 0 0 0 #9e58af;
  h6,
  h4 {
    color: #9e58af;
  }
}
.online {
  background: rgba(85, 133, 176, 0.3);
  box-shadow: inset 5px 0 0 0 #5585b0;
  h6,
  h4 {
    color: #5585b0;
  }
}
.pos {
  background: rgba(104, 191, 101, 0.3);
  box-shadow: inset 5px 0 0 0 #68bf65;
  h6,
  h4 {
    color: #68bf65;
  }
  // }
}

.seller_app {
  background: rgba(213, 233, 255, 1);
  box-shadow: inset 5px 0 0 0 #4ba9b6;

  h6,
  h4 {
    color: #4ba9b6;
  }
}

.orderCount {
  //text-decoration: underline;
  //text-decoration-style: dotted;
  cursor: pointer;
  //filter: brightness(110%);
  border-bottom: 2px dotted #000;
}
.login {
  position: absolute;
  width: 100%;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  .myglamm-logo {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    margin-bottom: 2.5rem;
    text-align: center;
    position: relative;
    display: flex;
    @media screen and (max-width: 992px) {
      width: 215px;
    }
  }
  h1 {
    font-size: 18px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    text-align: center;
    //text-transform: uppercase;
    @media screen and (min-width: 992px) {
      font-size: 24px;
    }
  }
}
.btn-google {
  width: 83% !important;
  border: 1px solid #000 !important;
  color: #000;
  font-weight: 600;
  border-radius: 2px !important;
  justify-content: center;
  display: flex;
  padding: 1rem !important;
  border-color: #808593 !important;
  text-align: center !important;
  margin: auto;
  @media screen and (min-width: 992px) {
    width: 30% !important;
  }
  img {
    float: none !important;
    margin: 0 7px 0 0 !important;
  }
}
.hide {
  display: none;
  visibility: hidden;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ff9797;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ff9797, 0 0 5px #ff9797;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
/* Remove these to get rid of the spinner */
/*Commented nprogress spinner */
// #nprogress .spinner {
//   display: block;
//   position: fixed;
//   z-index: 100;
//   top: 30px;
//   right: 30px;
// }

// #nprogress .spinner-icon {
//   width: 70px;
//   height: 70px;
//   box-sizing: border-box;

//   border: solid 2px transparent;
//   border-top-color: #ff9797;
//   border-left-color: #ff9797;
//   border-radius: 50%;

//   margin: auto;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   position: fixed;

//   -webkit-animation: nprogress-spinner 400ms linear infinite;
//   animation: nprogress-spinner 400ms linear infinite;
// }
// @-webkit-keyframes nprogress-spinner {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }
// @keyframes nprogress-spinner {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
.Toastify .Toastify__toast-body {
  margin: auto 6px;
  flex: 1 1 auto;
}
.Toastify .Toastify__toast-container {
  @media screen and (min-width: 992px) {
    width: 35%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-appear {
  opacity: 0;
  transform: scale(0.9);
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.font-bold {
  font-weight: 500;
}
.error-404 {
  position: absolute;
  width: 100%;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
}
.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  // z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #000;
  padding: 0;
}

.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}

.embla__button__svg {
  width: 100%;
  height: 100%;
}

.embla__button--prev {
  left: 27px;
}

.embla__button--next {
  right: 27px;
}
.embla__button {
  transform: none;
  top: auto;
  width: 13px;
  height: 13px;
}
.dropbtn {
  // background-color: #3498db;
  //color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  appearance: none;
}

.dropbtn:hover,
.dropbtn:focus {
  // background-color: #2980b9;
}

.dropdown {
  //position: absolute;
  //float: right;
  right: 0px;
  padding: 13px;
  @media screen and (min-width: 992px) {
    top: 0;
  }
  a {
    color: #000;
    text-decoration: none;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 1px solid #000;
    opacity: 0.5;
    font-weight: bold;
    // font-weight: bold;
    // &hover {
    //   color: #ff9797;
    // }
    // a:hover {
    //   color: #ff9797;
    // }
    &:hover {
      font-weight: bold;
      background: none;
      //color: #ff9797;
    }
  }
}

.dropdown-content {
  // display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.tooltip {
  position: relative;
  display: inline-block;
  //border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 130px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.text-right {
  text-align: right;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  //grid-gap: 10px;
  // background-color: #2196f3;
  //padding: 5px;
  row-gap: 1.5em;
  padding-bottom: 10px;
  column-gap: 0.5em;
}

.grid-item {
  grid-column: 1 / 3;
}

.select-orders {
  &:hover {
    filter: brightness(110%);
    cursor: pointer;
  }
}

.text-muted{
    color: #999;
    font-size: 0.8em;
    margin-bottom: 15px;
    margin-right: 5px;
}

.updated-at {
  display: flex;
  justify-content: center;
}

.refresh-icon {
  display: inline;
  width: 12px;
  height: 12px;
}

.domains-list {
  margin: 5px;
  li {
    list-style-type: none;
    padding: 10px;
    margin: 5px;
    a {
      text-decoration: none;
      color: rgb(100, 99, 99);
      display: block;
    }
    img {
      vertical-align: middle;
      height: 16px;
    }
    p{
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.vendorBtn {
  border: none;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  @media screen and (min-width: 992px) {
    width: 16%;
  }

}

.vendor-selector {
      display: flex;
      justify-content: center;
      align-items: center;
}

.vendors-list{
  img {
      vertical-align: middle;
      height: 16px;
      margin-left: 10px;
    }
    h4, h6{
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
      font-weight: 500;
      
      &.mgp, &.mgp-are, &.mgp-sau {
        color: #a57777;
        }
        
      &.stb {
        color: #4c7193;
        }
        
      &.tmc, &.tmc-are, &.tmc-sau {
        color: #5c899d;
        }

      &.bbc {
        color: #e57b81;
        }
        
      &.orh, &.orh-are, &.orh-sau {
        color: #58833b;
        }
        
      &.srn, &.srn-are, &.srn-sau {
        color: #874487;
        }
      
      &.lit {
        color: #9a8066;
        }

      &.blu {
        color: #898957;
      }
      
      &.orb {
        color: #6b6b7a;
      }

      &.popxo {
        color: #e4216c;
      }

      &.mnm {
        color: #8c7e5c;
      }

      &.tbb {
        color: #4b4e60;
      }

      }
    }

.text-center {
  text-align: center;
}

.title {
  margin-right: 15px;
}

.mgpLight, .mgp-areLight, .mgp-sauLight {
  background: #fee8e8;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #a57777;
  }
}

.stbLight {
  background: #dbe4ed;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #4c7193;
    }
}

.tmcLight, .tmc-areLight, .tmc-sauLight {
  background: #edf6fa;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #5c899d;
    }
}

.bbcLight {
  background: #f7edf5;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #e57b81;
    }
}

.orhLight, .orh-areLight, .orh-sauLight {
  background: #d6eec5;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #58833b;
    }
}

.srnLight, .srn-areLight, .srn-sauLight {
  background: #e9dbe9;
  &.bordered {
      box-shadow: inset 5px 0 0 0 #874487;
    }
}

.litLight {
  background: #fef3e8;
  &.bordered {
    box-shadow: inset 5px 0 0 0 #9a8066;
  }
}

.bluLight {
  background: #f0f0e7;
  &.bordered {
    box-shadow: inset 5px 0 0 0 #898957;
  }
}

.orbLight {
  background: #ececfe;

  &.bordered {
    box-shadow: inset 5px 0 0 0 #6b6b7a;
  }
}

.popxoLight {
  background: #fde7ef;

  &.bordered {
    box-shadow: inset 5px 0 0 0 #e4216c;
  }
}

.mnmLight {
  background: #faeed0;

  &.bordered {
    box-shadow: inset 5px 0 0 0 #8c7e5c;
  }
}

.tbbLight {
  background: #ededef;

  &.bordered {
    box-shadow: inset 5px 0 0 0 #4b4e60;
  }
}


.mgpDark, .mgp-areDark, .mgp-sauDark {
  background: #fcc7c7;
  color: #a57777;
}

.stbDark {
  background: #c5d4e2;
  color: #4c7193;
}

.tmcDark, .tmc-areDark, .tmc-sauDark {
  background: #d2e8f2;
  color: #5c899d;
}

.bbcDark {
  background: #efdceb;
  color: #d5757a;
}

.orhDark, .orh-areDark, .orh-sauDark {
  background: #c5e7ad;
  color: #58833b;
}

.srnDark, .srn-areDark, .srn-sauDark {
  background: #e3d2e3;
  color: #9d5f9d;
}

.litDark {
  background: #fde6d0;
  color: #9a8066;
}

.bluDark {
  background: #dcdcc8;
  color: #898957;
}

.orbDark {
  background: #e3e3fd;
  color: #6b6b7a;
}

.popxoDark {
  background: #fcd9eb;
  color: #e4216c;
}

.mnmDark {
  background: #f7e3b3;
  color: #8c7e5c;
}

.tbbDark {
  background: #d3d3d8;
  color: #4b4e60;
}

.vendorDropDown {
  position: relative;
  display: inline-block;
  top: 5%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  padding: 20px;
  overflow-y: auto;
  @media only screen and (max-device-width: 600px) {max-height: 60vh;}
  max-height: 90vh;
}

.vendorDropDown::-webkit-scrollbar {
  display: none;
}

.filterModal {
  position: absolute;

  // Mobile
  @media only screen and (max-device-width: 600px) {
    width: 100%;
    height: 100%;
  }
  // Tablet
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    left: 52%;
    top: 13%;
    width: 45%;
    height: 50%;
  }
  // Desktop
  @media screen and (min-device-width: 1024px) {
    top: 10%;
    left:70%;
    width: 28%;
    height: 80%;
  }
  // Larger Screens
 @media screen and (min-device-width: 1921px) and (max-device-width: 2560px) {
    top: 20%;
    left: 80%;
    width: 18%;
    height: 60%;
  }
  border-radius: 15px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  outline: none;
  padding-top: 10px;
  margin-top: 20px;
}

.filters-list{
  padding-left: 10px;
  h2 {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
  }  
}

.filter-item {
  padding-bottom:  10px;
}

.filterModalTitle {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  margin: 10px;
  h1 {
        text-align: center;
        font-size: 18px;
        padding: 15px 0 20px;
        color: #000000;
        font-weight: 500;
        margin-left: 5px;
    
        @media screen and (max-width: 992px) {
          margin-top: 5px;
        }
      }
  h4 {
    font-size: 14px; 
    cursor: pointer;
  }
}

.filter-button {
  display: inline-block;
  border: 1px solid rgba(128, 128, 128, 0.20);
  border-radius: 6px;
  background-color: #efefef;
  @media screen and (min-width: 992px) {
    margin: 0 15px 0 10px;
  }
}

.filter-icon {
  vertical-align: middle;
}

.filterModalContent{
  margin: 0 11px;
  height: auto;
  min-height: 100%;
  border-radius: 0;
  .close {
    margin-right: -16px;
    margin-top: -1px;
    @media screen and (max-width: 992px) {
      margin-left: 10px;
      margin-right: 6px;
    }
  }
}

.filterCount{
  top: 25%;
  background-color: #f7f7f7
}

.arrow-left {
  display: inline-block;
  width: 12px;
  height: 12px;
  cursor: pointer;
  //background: url("/images/left-arrow.svg") center center no-repeat !important;
}

.actions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.filterActions{
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  @media only screen and (max-device-width: 600px) {
  position: fixed;
  }
}

.btn {
  padding: 13px 33px 13px;
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
}

.apply-btn {
background: #ff9797;
font-weight: 600;
}

.cancel-btn {
  border: 1px solid #ff9797;
  background: white;
  color: #ff9797;
}

table, th, td {
  box-shadow: 1px 1px 0px 2px rgba(255, 255, 255, 0.5);
}

.filterHead {
      background-color: rgba(222, 222, 223, .24);
        color: #444;
        padding: 10px;
        text-align: left;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin-bottom: 10px;
        font-size: 15px;
}

.totalAmount, .orderCount1, .orderAmount {
  display: flex;
  justify-content: right;
  align-items: center;
}

.increased {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid green;
  margin-right: 2px;
}

.decreased {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid red;
  margin-right: 2px;
}

.no-change{
  width: 6px;
  border-bottom: 5px double #e79806;;
  margin-right: 3px;
}

.monthlySalesArrows {
  display: flex;
  justify-content: left;
  align-items: center;
}

.react-select__value-container {
  flex-wrap: nowrap !important;
}



.Select-multi-value-wrapper {
  white-space: nowrap;
  overflow-x: scroll;
  width: 250px;
}

.snapshot-container {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
}

.snapshot {
  border-radius: 10px;
  padding: 12px;
  box-shadow: 1px 4px 13px 1px #eaeaea;
  @media screen and (min-width: 992px) {
      width: 18%;
  }
  @media only screen and (max-device-width: 600px) {
    width: 85%;
    margin-bottom: 10px;
  }
 
  .tabs {
      text-align: center;
      display: flex;
      margin-bottom: 10px;
      justify-content: center;
      img {
          border: 2px solid transparent;
          border-radius: 50%;
          padding: 2px;
      }
      .react-tabs__tab {
        margin-right: 10px;
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
  
      .react-tabs__tab--selected {

        img {
          filter: grayscale(0);
          border-color: #ff9797;
        }
        background: none;
        border: none;
        margin-right: 10px;
        opacity: 1;
  
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }

  .snapshot-data {
      display: flex;
      font-size: 14px;
      padding-left: 8px;
      justify-content: space-between;
      color: #6d7077;
      font-weight: bold;
  
      .orders, .revenue {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
  
        h3 {
          font-size: 11px;
          padding-bottom: 4px;
        }
      }
    }

}

.mobileSnapshot{
  display: flex;
  justify-content: center;
}